













































import Vue from "vue";

export default Vue.component("SHCheckCardSkeleton", {
  components: {
    QSkeleton: () => import("../elements/QSkeleton.vue"),
  },
  methods: {
    handleClickCloseModal () {
      this.$emit('close');
    },
    handleClickSwitch () {
      this.$emit('switch');
    },
    handleClickNoSwitch () {
      this.$emit('noswitch');
    },
  },
});
